.blog-content {
	font-family: 'Amiri', 'Times New Roman', Times, serif;
	font-size: 1.5rem;
	line-height: 2.5rem;
}

.blog-title {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 700;
	color: #4b4954;
	font-size: 2.3rem;
	margin: 0;
	padding: 0 0 0.5rem 0;
}

.segoe {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.divider {
	margin: 1rem 0 2rem 0;
	border-bottom: 0.1rem solid crimson;
}
