.title {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: $x-large;
  font-weight: bold;
  margin-top: 60px;
  position: relative;
  text-transform: uppercase;
  
  span {
    left: 50%;
    transform: translateX(-50%);
  }
}

.underline {
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 20px;
  position: relative;
  width: 110px;
}

.line {
  &:before {
    background: $red;
    bottom: 0;
    content: '';
    height: 1px;
    position: absolute;
    right: 0;
    width: 110px;
  }

  &:after {
    background: $red;
    bottom: 5px;
    content: '';
    height: 1px;
    position: absolute;
    right: 16px;
    width: 75px;
  }
}

.primary-button {
  background: $red;
  border: 0;
  border-radius: 5px;
  box-shadow: 2px 2px 2px $dark-grey;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $normal;
  font-weight: normal;
  outline: 0;
  padding: 10px 35px;
  text-align: center;
  text-decoration: none;
  transition: .4s ease;

  &:hover {
    background: $dark-red !important;
    color: $white;
  }

  &>span {
    display: inline-block;
  }
}
