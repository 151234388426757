.footer {
  background-color: $light-grey;
  color: $white;
  padding: 5px 0;

  .row {
    width: 100%;
  }

  .copyright {
    text-align: left;
  }

  .footer-links {
    text-align: right;

    ol {
      &.breadcrumb {
        background: inherit;
        float: right;
        margin: 0;

        @include breakpoint($phone) {
          float: left;
        }

        li {
          a {
            color: $red !important;
          }
        }
      }
    }
  }
}
