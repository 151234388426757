.BlogCreator-content {
	box-sizing: border-box;
	width: 40%;
	float: left;
	margin: auto;
	padding: 3rem;
}

.BlogCreator-preview {
	box-sizing: border-box;
	width: 60%;
	float: left;
	padding: 3rem 5rem;
}

.BlogCreator-container::after {
	content: '';
	clear: both;
	display: block;
}

.page-header {
	text-align: center;
	padding: 2rem 1rem 0 1rem;
}

.toolbar {
	text-align: center;
	margin: 0;
	padding: 0;
}

.customButton {
	background: none;
	border: none;
	font-size: 1.5rem;
	padding: 0 2rem;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 400;
	cursor: pointer;
}

.button-save {
	color: limegreen;
}

.button-live {
	color: blueviolet;
}

.button-delete {
	color: crimson;
}

.customButton:focus,
.customButton:active {
	outline: none;
}

span {
	margin: 0;
	padding: 0;
}

.form-title {
	font-size: 1.5rem;
	font-weight: bold;
	width: 95%;
	height: fit-content;
}

.form > input {
	background: none;
	color: #4b4954;
	min-height: 2rem;
	margin: 0 2rem 2rem 0;
	border: none;
	border-bottom: 0.2rem dashed grey;
	padding-top: 0.7rem;
}

.form > input:focus {
	border-bottom: 0.2rem solid crimson;
	outline: none;
}

@media screen and (max-width: 600px) {
	.BlogCreator-content,
	.BlogCreator-preview {
		width: 100%;
		margin: auto;
		padding: 2.5rem;
	}
}
