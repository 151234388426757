.navigation {
  background-color: $dark-grey;

  .navbar-nav {
    .nav-link {
      font-size: $small;
      font-weight: bold;
      padding: 1rem 2rem !important;
      text-transform: uppercase;
      transition: .4s ease;

      &:hover {
        color: $red !important;
      }
    }
  }
}

.logo {
  line-height: 1;
  margin-right: 2rem;
}

.navbar-brand {
  font-size: $large;
  font-weight: 700;
}

.tagline {
  text-align: left;

  span {
    color: $white;
    font-size: 1rem;
  }
}
