.hero-image {
  img {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    object-fit: cover;

    @include breakpoint($tablet) {
      height: 300px;
      max-width: 100%;
      object-fit: cover;
    }
  }
}
