$phone: 600;
$tablet: 768;
$desktop: 1024;

@mixin breakpoint($point) {
  @if $point == $tablet {
    @media (max-width: 768px) { @content; }
  } @else if $point == $phone {
    @media (max-width: 600px) { @content; }
  } @else if $point == $desktop {
    @media (max-width: 1024px) { @content; }
  }
}