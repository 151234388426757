.post-container {
	box-sizing: border-box;
	width: 60%;
	margin: auto;
	padding: 3rem 5rem;
}

@media screen and (min-width: 300px) and (max-width: 1024px) {
	.post-container {
		width: 98%;
		padding: 3rem 2.5rem;
	}
}
