@import './colors';
@import './fonts';
@import './util';
@import './mixins';
@import './card';
@import './top-nav';
@import './navbar';
@import './hero';
@import './about-us';
@import './alabaster-moments';
@import './events';
@import './know-more';
@import './footer';

body {
  font-family: $lato-font;
  //height: 1500px;
}

.row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

a {
  color: $white;
  //font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  &:hover {
    text-decoration: none;
  }
}
