@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
.post-container {
	box-sizing: border-box;
	width: 60%;
	margin: auto;
	padding: 3rem 5rem;
}

@media screen and (min-width: 300px) and (max-width: 1024px) {
	.post-container {
		width: 98%;
		padding: 3rem 2.5rem;
	}
}

.blog-content {
	font-family: 'Amiri', 'Times New Roman', Times, serif;
	font-size: 1.5rem;
	line-height: 2.5rem;
}

.blog-title {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 700;
	color: #4b4954;
	font-size: 2.3rem;
	margin: 0;
	padding: 0 0 0.5rem 0;
}

.segoe {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.divider {
	margin: 1rem 0 2rem 0;
	border-bottom: 0.1rem solid crimson;
}

.BlogCreator-content {
	box-sizing: border-box;
	width: 40%;
	float: left;
	margin: auto;
	padding: 3rem;
}

.BlogCreator-preview {
	box-sizing: border-box;
	width: 60%;
	float: left;
	padding: 3rem 5rem;
}

.BlogCreator-container::after {
	content: '';
	clear: both;
	display: block;
}

.page-header {
	text-align: center;
	padding: 2rem 1rem 0 1rem;
}

.toolbar {
	text-align: center;
	margin: 0;
	padding: 0;
}

.customButton {
	background: none;
	border: none;
	font-size: 1.5rem;
	padding: 0 2rem;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 400;
	cursor: pointer;
}

.button-save {
	color: limegreen;
}

.button-live {
	color: blueviolet;
}

.button-delete {
	color: crimson;
}

.customButton:focus,
.customButton:active {
	outline: none;
}

span {
	margin: 0;
	padding: 0;
}

.form-title {
	font-size: 1.5rem;
	font-weight: bold;
	width: 95%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.form > input {
	background: none;
	color: #4b4954;
	min-height: 2rem;
	margin: 0 2rem 2rem 0;
	border: none;
	border-bottom: 0.2rem dashed grey;
	padding-top: 0.7rem;
}

.form > input:focus {
	border-bottom: 0.2rem solid crimson;
	outline: none;
}

@media screen and (max-width: 600px) {
	.BlogCreator-content,
	.BlogCreator-preview {
		width: 100%;
		margin: auto;
		padding: 2.5rem;
	}
}

.post-preview-container {
	display: flex;
	flex-wrap: wrap;
	margin: 2.5rem auto;
}

.button_add {
	width: 100%;
	text-align: center;
}

.custom-button {
	background: none;
	border: none;
	font-size: 1.5rem;
	padding: 0 2rem;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 400;
	cursor: pointer;
	color: crimson;
}

.preview-title {
	font-size: 1.3rem;
	font-weight: 600;
}

.section-header {
	text-align: center;
	padding: 2rem 1rem 0 1rem;
	color: #212121;
}

.preview-card {
	box-sizing: border-box;
	width: 29%;
	flex-shrink: 0;
	height: 10rem;
	overflow-y: hidden;
	margin: 1rem;
	padding: 0.7rem 1.5rem 0.5rem 1.5rem;
	color: #212121;
	box-shadow: 0.1rem 0.1rem 0.2rem gainsboro;
	border-bottom: none;
	-webkit-transform: border-bottom box-shadow 1s;
	        transform: border-bottom box-shadow 1s;
}

.preview-card:visited {
	color: #212121;
}

.preview-card:hover {
	border-bottom: 0.15rem solid crimson;
}

.content-preview {
	line-height: 1.5rem;
	color: #4b4954;
}

.link-container {
	text-align: center;
	margin: 2rem;
}

.link {
	text-decoration: none;
}

.blog-link {
	background: gray;
	margin: auto;
	padding: 0.75rem;
	color: whitesmoke;
	border-radius: 3rem;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
	.post-preview-container {
		margin: auto;
	}

	.preview-card {
		width: 45%;
		height: 12rem;
		justify-self: center;
		padding: 0 1.5rem 0.5rem 1.5rem;
	}
}

@media screen and (max-width: 767px) {
	.post-preview-container {
		margin: 0;
	}

	.preview-card {
		height: inherit;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		justify-self: center;
		padding: 0 1.5rem 0.5rem 1.5rem;
	}
}


.title {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 2.25rem;
  font-weight: bold;
  margin-top: 60px;
  position: relative;
  text-transform: uppercase; }
  .title span {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.underline {
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 20px;
  position: relative;
  width: 110px; }

.line:before {
  background: #d33;
  bottom: 0;
  content: '';
  height: 1px;
  position: absolute;
  right: 0;
  width: 110px; }

.line:after {
  background: #d33;
  bottom: 5px;
  content: '';
  height: 1px;
  position: absolute;
  right: 16px;
  width: 75px; }

.primary-button {
  background: #d33;
  border: 0;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #232323;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  outline: 0;
  padding: 10px 35px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: .4s ease;
  transition: .4s ease; }
  .primary-button:hover {
    background: #933 !important;
    color: #fff; }
  .primary-button > span {
    display: inline-block; }

.card-content {
  text-align: center;
  white-space: pre-line; }
  .card-content .card-title {
    font-size: 1.75rem;
    font-weight: bold; }
  .card-content img {
    height: 300px;
    max-width: 100%;
    object-fit: cover; }

.top-nav {
  background-color: #d33; }
  @media (max-width: 600px) {
    .top-nav .ml-auto {
      margin: 0 !important; } }
  .top-nav .link {
    color: #fff;
    font-size: 0.75rem;
    margin-right: 3rem; }
    @media (max-width: 600px) {
      .top-nav .link {
        text-align: left;
        margin: 5px 0; } }
    .top-nav .link svg,
    .top-nav .link span,
    .top-nav .link a {
      margin-right: .5rem; }
    .top-nav .link.social {
      margin: 0; }
      @media (max-width: 600px) {
        .top-nav .link.social {
          margin: 5px 0; } }
      .top-nav .link.social svg {
        font-size: 0.75rem;
        margin-left: 1rem; }

.navigation {
  background-color: #232323; }
  .navigation .navbar-nav .nav-link {
    font-size: 0.75rem;
    font-weight: bold;
    padding: 1rem 2rem !important;
    text-transform: uppercase;
    -webkit-transition: .4s ease;
    transition: .4s ease; }
    .navigation .navbar-nav .nav-link:hover {
      color: #d33 !important; }

.logo {
  line-height: 1;
  margin-right: 2rem; }

.navbar-brand {
  font-size: 1.75rem;
  font-weight: 700; }

.tagline {
  text-align: left; }
  .tagline span {
    color: #fff;
    font-size: 1rem; }

.hero-image img {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  object-fit: cover; }
  @media (max-width: 768px) {
    .hero-image img {
      height: 300px;
      max-width: 100%;
      object-fit: cover; } }

.about-us {
  background-color: #efefef;
  padding-bottom: 60px; }
  .about-us .row {
    padding: 50px 0; }
  .about-us p {
    text-align: left; }
    @media (max-width: 768px) {
      .about-us p {
        text-align: center; } }
  .about-us img {
    max-width: 100%; }
    @media (max-width: 600px) {
      .about-us img {
        margin: 0 0 30px 0; } }
  @media (max-width: 768px) {
    .about-us .boe-content {
      flex-direction: column-reverse;
      padding-top: 0; } }
  .about-us .boe-content img {
    width: 100%; }
  .about-us .boe-content .title,
  .about-us .leading-lady .title {
    font-size: 1.75rem;
    margin-top: 0; }
    @media (max-width: 600px) {
      .about-us .boe-content .title,
      .about-us .leading-lady .title {
        margin-top: 20px; } }
  .about-us .vision-mission .title {
    margin-top: 0; }
    .about-us .vision-mission .title span {
      font-size: 1.75rem; }
  .about-us .vision-mission p {
    text-align: center; }

.alabaster-moments {
  padding-bottom: 60px; }
  .alabaster-moments .moment-content .title span {
    font-size: 1.75rem; }
  @media (max-width: 600px) {
    .alabaster-moments .moment-content .title {
      margin-top: 0; } }

.event-block {
  background-color: #efefef;
  padding-bottom: 60px; }

.know-more {
  background-color: #d33;
  color: #fff;
  padding: 60px 0; }
  .know-more .row {
    width: 100%;
    text-align: center; }
  .know-more .title {
    display: block;
    font-size: 1.75rem;
    font-weight: normal;
    margin: 0;
    margin-bottom: 60px; }
  .know-more .know-more-content {
    text-align: left; }
    @media (max-width: 768px) {
      .know-more .know-more-content {
        text-align: center; } }
  @media (max-width: 600px) {
    .know-more .know-more-button {
      margin: 20px 0; } }
  .know-more .primary-button {
    background-color: rgba(47, 47, 47, 0.7); }
    .know-more .primary-button span {
      color: #fff; }
    .know-more .primary-button:hover {
      background-color: #232323 !important; }

.footer {
  background-color: #2f2f2f;
  color: #fff;
  padding: 5px 0; }
  .footer .row {
    width: 100%; }
  .footer .copyright {
    text-align: left; }
  .footer .footer-links {
    text-align: right; }
    .footer .footer-links ol.breadcrumb {
      background: inherit;
      float: right;
      margin: 0; }
      @media (max-width: 600px) {
        .footer .footer-links ol.breadcrumb {
          float: left; } }
      .footer .footer-links ol.breadcrumb li a {
        color: #d33 !important; }

body {
  font-family: "Lato", sans-serif; }

.row {
  width: 100%;
  margin-left: 0;
  margin-right: 0; }

a {
  color: #fff; }
  a:hover {
    text-decoration: none; }

