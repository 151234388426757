.post-preview-container {
	display: flex;
	flex-wrap: wrap;
	margin: 2.5rem auto;
}

.button_add {
	width: 100%;
	text-align: center;
}

.custom-button {
	background: none;
	border: none;
	font-size: 1.5rem;
	padding: 0 2rem;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 400;
	cursor: pointer;
	color: crimson;
}

.preview-title {
	font-size: 1.3rem;
	font-weight: 600;
}

.section-header {
	text-align: center;
	padding: 2rem 1rem 0 1rem;
	color: #212121;
}

.preview-card {
	box-sizing: border-box;
	width: 29%;
	flex-shrink: 0;
	height: 10rem;
	overflow-y: hidden;
	margin: 1rem;
	padding: 0.7rem 1.5rem 0.5rem 1.5rem;
	color: #212121;
	box-shadow: 0.1rem 0.1rem 0.2rem gainsboro;
	border-bottom: none;
	transform: border-bottom box-shadow 1s;
}

.preview-card:visited {
	color: #212121;
}

.preview-card:hover {
	border-bottom: 0.15rem solid crimson;
}

.content-preview {
	line-height: 1.5rem;
	color: #4b4954;
}

.link-container {
	text-align: center;
	margin: 2rem;
}

.link {
	text-decoration: none;
}

.blog-link {
	background: gray;
	margin: auto;
	padding: 0.75rem;
	color: whitesmoke;
	border-radius: 3rem;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
	.post-preview-container {
		margin: auto;
	}

	.preview-card {
		width: 45%;
		height: 12rem;
		justify-self: center;
		padding: 0 1.5rem 0.5rem 1.5rem;
	}
}

@media screen and (max-width: 767px) {
	.post-preview-container {
		margin: 0;
	}

	.preview-card {
		height: inherit;
		width: fit-content;
		justify-self: center;
		padding: 0 1.5rem 0.5rem 1.5rem;
	}
}
