@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

$lato-font: 'Lato', sans-serif;
$open-sans-font: 'Open Sans', Helvetica, Arial, sans-serif;

// Font sizes
$small: .75rem;
$normal: 1rem;
$large: 1.75rem;
$x-large: 2.25rem;
